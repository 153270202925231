import {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router';

import useAppStoreSelector from '../store/useAppStoreSelector';

import AuthenticateCallback from '../../pages/Auth/AuthCallback';
import SelectOrg from '../../pages/Auth/SelectOrg';
import ConnectWarehouseRoutes from '../../pages/Connections/ConnectWarehouse';

const AuthenticatedRoutesWithLayout = lazy(() =>
  import(
    /* webpackChunkName: "authenticated-routes" */
    './AuthenticatedRoutesWithLayout'
  )
);

export default function AllRoutes() {
  const {user, orgUsers} = useAppStoreSelector((s) => s.state);
  const isCurrentUserAdmin =
    orgUsers?.find((item) => item.id === user.id)?.user_role === 'admin';

  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route
        path="/connect-warehouse"
        element={
          isCurrentUserAdmin ? <ConnectWarehouseRoutes /> : <Navigate to="/dashboards" />
        }
      >
        <Route
          path="*"
          element={
            isCurrentUserAdmin ? (
              <AuthenticatedRoutesWithLayout />
            ) : (
              <Navigate to="/workspaces" />
            )
          }
        />
      </Route>
      <Route path="/select-org" element={<SelectOrg />} />
      <Route path="*" element={<AuthenticatedRoutesWithLayout />} />
    </Routes>
  );
}
