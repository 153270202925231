import {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router';

const SelectOrg = lazy(() => import('../../pages/Auth/SelectOrg'));

const ConnectWarehouseRoutes = lazy(() =>
  import('../../pages/Connections/ConnectWarehouse')
);

export default function WarehouseNotConnectedRoutes() {
  return (
    <Routes>
      <Route path="/select-org" element={<SelectOrg />} />
      <Route path="/connect-warehouse" element={<ConnectWarehouseRoutes />}>
        <Route path="*" element={<ConnectWarehouseRoutes />} />
      </Route>
      <Route path="*" element={<Navigate to="/connect-warehouse" replace />} />
    </Routes>
  );
}
