type RoutePriority = 'CRITICAL' | 'DEFERRED';

type RouteConfig = {
  import: () => Promise<{default: React.ComponentType<Record<string, unknown>>}>;
  priority: RoutePriority;
  routes?: string[];
};

export const ROUTES: Record<string, RouteConfig> = {
  AuthenticatedRoutes: {
    import: () =>
      import(
        /* webpackChunkName: "authenticated-routes" */
        '../app/routes/AuthenticatedRoutesWithLayout'
      ),
    priority: 'CRITICAL',
  },
  Workspaces: {
    import: () =>
      import(
        /* webpackChunkName: "workspaces" */
        '../features/workspace/components/WorkspacesListView'
      ),
    priority: 'DEFERRED',
    routes: ['/dashboards', '/'],
  },
  Workspace: {
    import: () =>
      import(
        /* webpackChunkName: "workspace" */
        '../pages/Workspace/Workspace'
      ),
    priority: 'DEFERRED',
    routes: ['/dashboards/*'],
  },
  EventPreview: {
    import: () =>
      import(
        /* webpackChunkName: "event-preview" */
        '../features/event/components/EventPreview'
      ),
    priority: 'CRITICAL',
  },
  Events: {
    import: () =>
      import(
        /* webpackChunkName: "events" */
        '../pages/Events/EventsRouter'
      ),
    priority: 'DEFERRED',
    routes: ['/events', '/events/*'],
  },
  Cohorts: {
    import: () =>
      import(
        /* webpackChunkName: "cohorts" */
        '../pages/Cohorts/CohortsRouter'
      ),
    priority: 'DEFERRED',
    routes: ['/cohorts', '/cohorts/*'],
  },
  UserActivity: {
    import: () =>
      import(
        /* webpackChunkName: "user-activity" */
        '../pages/UserActivity/UserActivity'
      ),
    priority: 'DEFERRED',
    routes: ['/user-activity', '/user-activity/*'],
  },
  Admin: {
    import: () =>
      import(
        /* webpackChunkName: "admin" */
        '../pages/Admin/AdminRouter'
      ),
    priority: 'DEFERRED',
    routes: ['/admin', '/admin/*'],
  },
  CreateVisualizations: {
    import: () =>
      import(
        /* webpackChunkName: "create-visualizations" */
        '../pages/CreateVisualizations/CreateVisualizations'
      ),
    priority: 'DEFERRED',
    routes: ['/visualizations', '/visualizations/*'],
  },
};

function preloadRoutesByPriority(priority: RoutePriority) {
  for (const route of Object.values(ROUTES)) {
    if (route.priority === priority) {
      route.import().catch((error) => {
        console.warn(`Failed to preload route: ${error.message}`);
      });
    }
  }
}

export function preloadCriticalRoutes() {
  preloadRoutesByPriority('CRITICAL');
}

export function preloadRouteByPathname(pathname: string) {
  // we only loop through routes once and return early if route is not defined
  for (const route of Object.values(ROUTES)) {
    if (!route?.routes) continue;

    // add our check here for a match
    const hasMatch = route.routes.some((pattern) => {
      const basePattern = pattern.replace(/\/\*$/, '');
      return pathname === basePattern || pathname.startsWith(basePattern + '/');
    });

    // then preload if there is a match
    if (hasMatch) {
      route.import().catch((error) => {
        console.warn(`Failed to preload route: ${error.message}`);
      });
    }
  }
}

export function preloadDeferredRoutes() {
  if ('requestIdleCallback' in window) {
    window.requestIdleCallback(() => {
      preloadRoutesByPriority('DEFERRED');
    });
  }
}
