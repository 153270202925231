import {Divider, Dropdown, Typography} from 'antd';
import {CSSProperties, ReactNode, useState} from 'react';
import {useNavigate} from 'react-router';
import config from '../config';
import Analytics from '../services/analytics/Analytics';
import {appEventsPA} from '../services/analytics/appEvents';
import useAppStoreSelector from '../store/useAppStoreSelector';
import styles from './styles.module.css';

type DropdownMenuProps = {
  style?: CSSProperties;
  closeDropDown: () => void;
};

function DropdownMenu({style, closeDropDown}: DropdownMenuProps) {
  const logout = useAppStoreSelector((s) => s.logout);
  const {state, switchActiveOrganization} = useAppStoreSelector((s) => s);
  const navigate = useNavigate();
  const isDemoOrg = state.user?.organization?.id === config.demoOrgId;

  return (
    <div
      style={{
        width: '100%',
        zIndex: 99999,
        marginTop: 8,
        ...(style || {}),
        borderRadius: 6,
        border: '1px solid var(--border)',
      }}
    >
      <div style={{padding: 8, background: '#fff', borderRadius: 6}}>
        {state.user?.organizations?.length > 1 && (
          <>
            {isDemoOrg ? (
              <div
                onClick={() => {
                  closeDropDown();
                  navigate(`/select-org`);
                }}
                className={styles.dropdownMenuItem}
              >
                <Typography.Text className={styles.dropdownMenuItemText}>
                  Switch to Org Account
                </Typography.Text>
              </div>
            ) : (
              <div
                onClick={() => {
                  closeDropDown();
                  switchActiveOrganization(config.demoOrgId, () => navigate('/'));
                }}
                className={styles.dropdownMenuItem}
              >
                <Typography.Text className={styles.dropdownMenuItemText}>
                  Switch to Demo Account
                </Typography.Text>
              </div>
            )}

            <Divider style={{margin: 4}} />
          </>
        )}

        <div
          onClick={() => {
            Analytics.track(appEventsPA.LOGOUT_CLICKED);
            closeDropDown();
            logout();
          }}
          className={styles.dropdownMenuItem}
        >
          <Typography.Text className={styles.dropdownMenuItemText}>
            Sign Out
          </Typography.Text>
        </div>
      </div>
    </div>
  );
}

type Props = {
  children: ReactNode;
  dropDownStyle?: CSSProperties;
};

export default function AccountDropDown({children, dropDownStyle}: Props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      open={isOpen}
      overlay={
        <DropdownMenu closeDropDown={() => setIsOpen(false)} style={dropDownStyle} />
      }
      overlayStyle={dropDownStyle}
      trigger={['click']}
      onOpenChange={setIsOpen}
    >
      <div className="ant-dropdown-link" style={{cursor: 'pointer', width: '90%'}}>
        {children}
      </div>
    </Dropdown>
  );
}
