import {lazy} from 'react';
import {Route, Routes} from 'react-router';

const Authenticate = lazy(() => import('../../pages/Auth/Authenticate'));
const AuthenticateCallback = lazy(() => import('../../pages/Auth/AuthCallback'));

export default function NonAuthenticatedRoutes() {
  return (
    <Routes>
      <Route path="/auth" element={<AuthenticateCallback />} />
      <Route path="*" element={<Authenticate />} />
    </Routes>
  );
}
