import {Alert, Layout} from 'antd';
import {CSSProperties, ReactNode, useEffect} from 'react';
import PageHeader, {PageHeaderProps} from './Headers/PageHeader';

const {Content} = Layout;

const defaultHeaderOptions = {};

type PageContentProps =
  | {
      children: ReactNode;
      pageTitle?: string;
      noHeader: true;
      className?: string;
      id?: string;
      style?: CSSProperties;
      bannerText?: string;
    }
  | {
      children: ReactNode;
      noHeader?: false;
      pageTitle?: string;
      className?: string;
      id?: string;
      headerOptions: PageHeaderProps;
      style?: CSSProperties;
      bannerText?: string;
    };

export default function PageContent(props: PageContentProps) {
  let headerOptions = defaultHeaderOptions;

  const {className, id, pageTitle, noHeader, children, style, bannerText} = props;

  if (!noHeader) {
    headerOptions = props.headerOptions;
  }

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
    }
  }, [pageTitle]);

  return (
    <Layout
      className={className}
      style={{
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      {!noHeader && <PageHeader {...headerOptions} />}
      {bannerText && (
        <Alert
          message={bannerText}
          banner
          type="info"
          style={{top: '0', textAlign: 'center'}}
          closable
          showIcon={false}
        />
      )}
      <Content
        style={{
          margin: 0,
          height: '100%',
          overflowY: 'auto',
        }}
      >
        <div
          id={id}
          style={{
            margin: '0 auto',
            width: '100%',
            height: '100%',
            ...(style || {}),
          }}
        >
          {children}
        </div>
      </Content>
    </Layout>
  );
}
